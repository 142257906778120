var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{class:[
    'new-catalog-page',
    {
      'new-catalog-page--mobile': _vm.$device.isMobile,
    } ]},[_c('header',{staticClass:"new-catalog-page__header"},[_c('h1',[_vm._v(_vm._s(_vm.title || _vm.pageTitle))]),_vm._v(" "),(_vm.bannerUrl)?_c('img',{attrs:{"src":_vm.bannerUrl,"alt":_vm.pageTitle}}):_vm._e(),_vm._v(" "),(_vm.bannerUrl)?_c('br'):_vm._e(),_vm._v(" "),(_vm.pageDescription)?_c('see-more',{attrs:{"text":_vm.pageDescription}}):_vm._e()],1),_vm._v(" "),_c('section',{staticClass:"new-catalog-page__body"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.showDeviceModal)?_c('catalog-modal',{attrs:{"brands":_vm.brands,"device-code":_vm.selectedDeviceCode},on:{"select":_vm.selectDevice,"close":function($event){_vm.showDeviceModal = false}}}):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.showCaseTypeModal)?_c('middle-modal',{staticClass:"new-catalog-page__case-types",on:{"close":function($event){_vm.newSelectedCaseType = null
          _vm.newSelectedCaseTypeGroup = null
          _vm.showCaseTypeModal = false}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',[_vm._v(_vm._s(_vm.$t(("select_" + _vm.translationPrefix + "_type"))))])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"new-catalog-page__case-types-list"},_vm._l((_vm.availableCaseTypeGroupsBySelectedDevice),function(caseTypeGroup){return _c('div',{key:caseTypeGroup.name,class:[
                'new-catalog-page__case-types-item',
                {
                  'new-catalog-page__case-types-item--active': _vm.newSelectedCaseTypeGroup
                    ? _vm.newSelectedCaseTypeGroup.name === caseTypeGroup.name
                    : _vm.selectedCaseTypeGroup &&
                      _vm.selectedCaseTypeGroup.name === caseTypeGroup.name,
                } ],on:{"click":function($event){return _vm.selectCaseTypeGroup(caseTypeGroup)}}},[_c('img',{attrs:{"src":caseTypeGroup.image_url,"alt":caseTypeGroup.name}}),_vm._v(" "),_c('div',{staticClass:"new-catalog-page__case-types-item-body"},[_c('h4',[_vm._v(_vm._s(caseTypeGroup.name))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(caseTypeGroup.description))]),_vm._v(" "),(_vm.caseTypeGroupPrice(caseTypeGroup.case_device_id))?_c('div',{staticClass:"new-catalog-page__case-types-item-price"},[_vm._v("\n                  "+_vm._s(_vm.caseTypeGroupPrice(caseTypeGroup.case_device_id))+"\n                ")]):_vm._e()])])}),0),_vm._v(" "),(
              _vm.filteredCaseTypesBySelectedCaseTypeGroup.length > 1 &&
              (_vm.newSelectedCaseTypeGroup || _vm.selectedCaseTypeGroup)
            )?_c('div',{staticClass:"new-catalog-page__case-types-childs"},[_c('h4',[_vm._v("\n              COR ("+_vm._s(_vm.newSelectedCaseType
                  ? _vm.newSelectedCaseType.name
                  : _vm.selectedCaseType && _vm.selectedCaseType.name)+"):\n            ")]),_vm._v(" "),_vm._l((_vm.filteredCaseTypesBySelectedCaseTypeGroup),function(caseType){return _c('div',{key:caseType.name,class:[
                'new-catalog-page__case-types-child',
                {
                  'new-catalog-page__case-types-child--active': _vm.newSelectedCaseType
                    ? _vm.newSelectedCaseType.name === caseType.name
                    : _vm.selectedCaseType &&
                      _vm.selectedCaseType.name === caseType.name,
                } ],on:{"click":function($event){_vm.newSelectedCaseType = caseType}}},[_c('img',{attrs:{"src":caseType.case_image,"alt":caseType.name}})])})],2):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"new-catalog-page__case-types-footer"},[_c('button',{staticClass:"new-catalog-page__case-types-select",on:{"click":_vm.selectCaseType}},[_vm._v("\n              SELECIONAR\n            ")])])]},proxy:true}],null,false,3658370720)}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"new-catalog-page__filters"},[(_vm.useCaseScope)?_c('button',{class:[
          'new-catalog-page__filters-filter',
          {
            'new-catalog-page__filters-filter--active': !!_vm.selectedDevice,
          } ],on:{"click":function($event){_vm.showDeviceModal = true}}},[(!_vm.selectedDevice)?_c('span',[_vm._v(_vm._s(_vm.$t((_vm.translationPrefix + "_model"))))]):_c('span',[_vm._v(_vm._s(_vm.selectedDevice.name))]),_vm._v(" "),_c('icon-arrow-down',{staticClass:"new-catalog-page__filters-filter-icon-down"})],1):_vm._e(),_vm._v(" "),(_vm.useCaseScope)?_c('button',{class:[
          'new-catalog-page__filters-filter',
          {
            'new-catalog-page__filters-filter--case-type-active': !!_vm.selectedCaseType,
            'new-catalog-page__filters-filter--active': !!_vm.selectedCaseType,
          } ],on:{"click":_vm.openCaseTypeModal}},[(_vm.selectedCaseType)?_c('img',{attrs:{"src":_vm.selectedCaseType.case_image,"alt":"Case Type Image"}}):_vm._e(),_vm._v(" "),(!_vm.selectedCaseType)?_c('span',[_vm._v(_vm._s(_vm.$t((_vm.translationPrefix + "_type"))))]):_c('span',[_vm._v(_vm._s(_vm.selectedCaseType.name))]),_vm._v(" "),_c('icon-arrow-down',{staticClass:"new-catalog-page__filters-filter-icon-down"})],1):_vm._e(),_vm._v(" "),(_vm.enable360PrintFilter)?_c('button',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
          function () { return _vm.showPrintTypeModal ? (_vm.showPrintTypeModal = false) : function () { return ({}); }; }
        ),expression:"\n          () =>\n            showPrintTypeModal ? (showPrintTypeModal = false) : () => ({})\n        "}],class:[
          'new-catalog-page__filters-filter',
          {
            'new-catalog-page__filters-filter--active': !!_vm.selectedPrintType,
          } ],on:{"click":function($event){_vm.showPrintTypeModal = true}}},[(!_vm.selectedPrintType)?_c('span',[_vm._v(_vm._s(_vm.$t('print_type')))]):_c('span',[_vm._v(_vm._s(_vm.selectedPrintType.name))]),_vm._v(" "),_c('icon-arrow-down',{staticClass:"new-catalog-page__filters-filter-icon-sort"}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.showPrintTypeModal)?_c('div',{staticClass:"new-catalog-page__filters-sort-modal"},[_c('h4',[_vm._v(_vm._s(_vm.$t('print_type')))]),_vm._v(" "),_vm._l((_vm.sortByPrints),function(sort){return _c('div',{key:sort.code,class:[
                'new-catalog-page__filters-sort-modal-item',
                {
                  'new-catalog-page__filters-sort-modal-item--active':
                    _vm.selectedPrintType && _vm.selectedPrintType.code === sort.code,
                } ],on:{"click":function($event){return _vm.selectPrint(sort)}}},[_vm._v("\n              "+_vm._s(sort.name)+"\n            ")])})],2):_vm._e()])],1):_vm._e(),_vm._v(" "),(_vm.isPowerBankPage || (_vm.materialsFilter && _vm.materialsFilter.length))?_c('button',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
          function () { return _vm.showMaterialFilter ? (_vm.showMaterialFilter = false) : function () { return ({}); }; }
        ),expression:"\n          () =>\n            showMaterialFilter ? (showMaterialFilter = false) : () => ({})\n        "}],class:[
          'new-catalog-page__filters-filter',
          {
            'new-catalog-page__filters-filter--active': !!_vm.selectedPrintType,
          } ],on:{"click":function($event){_vm.showMaterialFilter = true}}},[(!_vm.selectedMaterialFilter)?_c('span',[_vm._v("Cor do Material")]):_c('span',[_vm._v(_vm._s(_vm.selectedMaterialFilter.name))]),_vm._v(" "),_c('icon-arrow-down',{staticClass:"new-catalog-page__filters-filter-icon-sort"}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.showMaterialFilter)?_c('div',{staticClass:"new-catalog-page__filters-sort-modal"},[_c('h4',[_vm._v("Cor do Material")]),_vm._v(" "),_vm._l((_vm.sortByMaterialsFilter),function(material){return _c('div',{key:material.code,class:[
                'new-catalog-page__filters-sort-modal-item',
                {
                  'new-catalog-page__filters-sort-modal-item--active':
                    _vm.selectedMaterialFilter &&
                    _vm.selectedMaterialFilter.code === material.code,
                } ],on:{"click":function($event){return _vm.selectMaterialFilter(material)}}},[_vm._v("\n              "+_vm._s(material.name)+"\n            ")])})],2):_vm._e()])],1):_vm._e(),_vm._v(" "),_c('button',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
          function () { return (_vm.showSortModal ? (_vm.showSortModal = false) : function () { return ({}); }); }
        ),expression:"\n          () => (showSortModal ? (showSortModal = false) : () => ({}))\n        "}],class:[
          'new-catalog-page__filters-filter',
          {
            'new-catalog-page__filters-filter--active': !!_vm.selectedSort,
          } ],on:{"click":function($event){_vm.showSortModal = true}}},[(!_vm.selectedSort)?_c('span',[_vm._v("Ordenar Por")]):_c('span',[_vm._v(_vm._s(_vm.selectedSort.name))]),_vm._v(" "),_c('icon-up-down',{staticClass:"new-catalog-page__filters-filter-icon-sort"}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.showSortModal)?_c('div',{staticClass:"new-catalog-page__filters-sort-modal"},[_c('h4',[_vm._v("Ordenar Por:")]),_vm._v(" "),_vm._l((_vm.sortByValues),function(sort){return _c('div',{key:sort.code,class:[
                'new-catalog-page__filters-sort-modal-item',
                {
                  'new-catalog-page__filters-sort-modal-item--active':
                    _vm.selectedSort && _vm.selectedSort.code === sort.code,
                } ],on:{"click":function($event){return _vm.selectSort(sort)}}},[_vm._v("\n              "+_vm._s(sort.name)+"\n            ")])})],2):_vm._e()])],1)]),_vm._v(" "),_c('div',{staticClass:"new-catalog-page__section-title"},[_c('h4',[_vm._v("\n        Coleções disponíveis\n        "),(
            _vm.lastSelectedCategory ||
            (_vm.selectedCategory && _vm.selectedCategory.children.length)
          )?_c('span',[_vm._v("em\n          "),_c('b',[_vm._v("\n            "+_vm._s(_vm.selectedCategory.children.length
                ? _vm.selectedCategory.name
                : _vm.lastSelectedCategory && _vm.lastSelectedCategory.name)+"\n          ")])]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"new-catalog-page__categories-container"},[_c('div',{class:[
          'new-catalog-page__categories',
          {
            'new-catalog-page__categories--mobile': _vm.$device.isMobile,
          } ]},_vm._l((_vm.categoriesToShow),function(category){return _c('button',{key:category.id,class:[
            'new-catalog-page__categories-item',
            {
              'new-catalog-page__categories-item--active':
                _vm.selectedCategory && _vm.selectedCategory.id === category.id,
            } ],on:{"click":function($event){return _vm.selectCategory(category)}}},[_vm._v("\n          "+_vm._s(category.name)+"\n        ")])}),0)]),_vm._v(" "),_c('div',{staticClass:"new-catalog-categories__nav"},[_c('button',{class:[
          'new-catalog-categories__nav-item',
          'new-catalog-categories__nav-item--left',
          {
            'new-catalog-categories__nav-item--hide':
              !_vm.translateX || _vm.translateX === 0 || _vm.$device.isMobile,
          } ],on:{"click":function($event){return _vm.navGallery(1)}}},[_c('ArrowLeft',{staticClass:"new-catalog-categories__arrow"})],1),_vm._v(" "),_c('button',{class:[
          'new-catalog-categories__nav-item',
          'new-catalog-categories__nav-item--right',
          {
            'new-catalog-categories__nav-item--hide': _vm.$device.isMobile,
          } ],on:{"click":function($event){return _vm.navGallery(-1)}}},[_c('ArrowRight',{staticClass:"new-catalog-categories__arrow"})],1)]),_vm._v(" "),(_vm.tags.length)?_c('div',{staticClass:"new-catalog-page__section-title"},[_c('h4',[_vm._v("Filtros selecionados")])]):_vm._e(),_vm._v(" "),(_vm.tags.length)?_c('div',{staticClass:"new-catalog-page__tags"},_vm._l((_vm.tags),function(tag){return _c('button',{key:tag.name,class:['new-catalog-page__tags-item']},[_vm._v("\n        "+_vm._s(tag.name)+"\n        "),_c('span',{staticClass:"new-catalog-page__tags-item-remove",on:{"click":function($event){return _vm.removeTag(tag)}}},[_c('cross-icon')],1)])}),0):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.showScrollPageToTop && !_vm.showDeviceModal)?_c('div',{staticClass:"new-catalog-page__to-top",on:{"click":_vm.gotoTop}},[_c('small',[_vm._v("Página: "+_vm._s(_vm.page)+"/"+_vm._s(_vm.pageQuantity))]),_vm._v(" "),_c('button',[_c('icon-arrow-up')],1)]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"section new-catalog-page__products"},[_c('p',[_vm._v("\n      "+_vm._s(_vm.totalProducts)+" produtos encontrados\n      "),_c('transition',{attrs:{"name":"fade"}},[(_vm.loadingProducts)?_c('span',[_c('three-dots-loading')],1):_vm._e()])],1),_vm._v(" "),(_vm.products.length)?_c('products-grid',{attrs:{"case-device":_vm.caseDeviceSlug,"case-device-id":_vm.caseDeviceId,"products":_vm.products,"send-gtm-data":true,"max-grid-columns":4,"allow-all-colors":_vm.allowAllColors}}):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.loadingProducts)?_c('span',{staticClass:"products-loading"},[_c('logo-loading')],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }