<template>
  <div class="banners g-nav-arrow">
    <vue-glide
      :per-view="1"
      :autoplay="5000"
      :hoverpause="false"
      :rewind="true"
      :bullet="true"
      :gap="0"
    >
      <vue-glide-slide>
        <a
          :href="adaptUrl(banners[0].link_url)"
          @dragstart="dragStart"
          @click.prevent="bannerRedirect(banners[0])"
        >
          <banners-img
            :banner-image="getResponsiveBannerSrc(banners[0])"
            :mobile-src="banners[0].mobile_image"
            :desktop-src="banners[0].desktop_image"
            :banner-name="banners[0].name"
            class="loaded banners__picture"
            importance="high"
            data-test="banner-img"
          />
        </a>
      </vue-glide-slide>
      <vue-glide-slide v-for="(banner, idx) in banners.slice(1)" :key="idx">
        <a
          :href="adaptUrl(banner.link_url)"
          @dragstart="dragStart"
          @click.prevent="bannerRedirect(banner)"
        >
          <banners-img
            :banner-image="getResponsiveBannerSrc(banner)"
            :mobile-src="banner.mobile_image"
            :desktop-src="banner.desktop_image"
            :banner-name="banner.name"
            class="banners__picture"
            data-test="banner-img"
            @onloadedImage="refreshGlide"
          />
        </a>
      </vue-glide-slide>

      <!-- controls -->
      <template v-if="banners.length && banners.length > 1" slot="control">
        <button
          class="banners__prev-btn g-nav-arrow__btn g-nav-arrow__btn--prev"
          data-glide-dir="<"
        >
          <arrowLeft />
        </button>
        <button
          class="banners__next-btn g-nav-arrow__btn g-nav-arrow__btn--next"
          data-glide-dir=">"
        >
          <arrowRight />
        </button>
      </template>
    </vue-glide>
  </div>
</template>

<script>
import { Glide, GlideSlide } from '@gedhean/vue-glide-js'
import '@gedhean/vue-glide-js/dist/vue-glide.css'
import BannersImg from '../banners-img/BannersImg.vue'
import isExternalLink from '~/utils/isExternalLink'
import pushDataLayer from '~/mixins/gtm/push-data-layer'
import ArrowLeft from '~/components/icons/arrow-left/ArrowLeft'
import ArrowRight from '~/components/icons/arrow-right/ArrowRight'
import adaptUrl from '~/commun/utils/adaptUrl'
import bannelable from '~/mixins/bannelable'

export default {
  name: 'Banners',
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
    ArrowLeft,
    ArrowRight,
    BannersImg,
  },
  mixins: [bannelable, pushDataLayer],
  props: {
    banners: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dragging: false,
    }
  },
  computed: {
    isDesktop() {
      return this.$device.isDesktop
    },
  },
  mounted() {
    const formattedBanners = this.banners.map((banner) => {
      return this.bannerToGtm(banner)
    })
    this.sendToGtm(formattedBanners, 'navigation', 'promotionImpression')
  },
  methods: {
    adaptUrl,
    dragStart() {
      this.dragging = true
    },
    bannerRedirect(banner) {
      const adaptedUrl = adaptUrl(banner.link_url)
      if (this.dragging) {
        this.dragging = false
        return
      }

      this.sendToGtm(
        this.bannerToGtm(banner),
        'interactWithPage',
        'clickOnLink',
        'clickOnPromotion'
      )

      if (isExternalLink(adaptedUrl)) {
        window.open(adaptedUrl, '_blank')
      } else {
        this.$router.push(adaptedUrl)
      }
    },
    refreshGlide(imgSrc) {
      const cloneImg = document.querySelector(
        `.glide__slide--clone [data-src='${imgSrc}']`
      )

      if (cloneImg && !cloneImg.src) {
        cloneImg.src = imgSrc
        cloneImg.classList.add('loaded')
      }
    },
    sendToGtm(value, category, action, label = undefined) {
      const data = {}
      data.event = 'genericEvent'
      data.eventValue = value
      data.eventCategory = category
      data.eventAction = action
      data.eventLabel = label
      this.pushDataLayer(data)
    },
    bannerToGtm(banner) {
      // Fix position
      return {
        name: banner.name,
        position: this.title || 'home',
        url: banner.link_url,
      }
    },
    getResponsiveBannerSrc(banner) {
      if (this.isDesktop) return banner.desktop_image

      return banner.mobile_image
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/css/g-nav-arrow.scss';

.banners {
  position: relative;
  background-color: #f2f2f6;
  padding-top: 497px / 414px * 100%;

  .glide {
    position: absolute;
    top: 0;
    bottom: 0;
  }
}

.glide__bullets {
  $bullet__hover-color: #ccc;

  position: absolute;
  bottom: 15px;
  width: 130px;
  left: calc(50% - (130px / 2));
  text-align: center;

  .glide__bullet {
    font-size: 0;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: 0;
    background-color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
    margin: 20px 3px 0;
    outline: none;
    padding: 0;
    display: inline-block;

    &:hover {
      transition: background-color 0.2s ease;
      background-color: rgba(#ffffff, 0.6);
    }
  }

  .glide__bullet--active {
    background-color: white;
  }
}

@media (min-width: 769px) {
  .banners {
    padding-top: 359px / 1437px * 100%;
  }

  .glide__bullets {
    display: flex;
    align-items: center;
    bottom: 20px;

    .glide__bullet {
      width: 38px;
      height: 2px;
      border-radius: 4px;

      &.glide__bullet--active {
        height: 4px;
      }
    }
  }
}
</style>
