<template>
  <main
    :class="[
      'new-catalog-page',
      {
        'new-catalog-page--mobile': $device.isMobile,
      },
    ]"
  >
    <header class="new-catalog-page__header">
      <h1>{{ title || pageTitle }}</h1>
      <img v-if="bannerUrl" :src="bannerUrl" :alt="pageTitle" />
      <br v-if="bannerUrl" />
      <see-more v-if="pageDescription" :text="pageDescription" />
    </header>
    <section class="new-catalog-page__body">
      <transition name="fade">
        <catalog-modal
          v-if="showDeviceModal"
          :brands="brands"
          :device-code="selectedDeviceCode"
          @select="selectDevice"
          @close="showDeviceModal = false"
        />
      </transition>

      <transition name="fade">
        <middle-modal
          v-if="showCaseTypeModal"
          class="new-catalog-page__case-types"
          @close="
            newSelectedCaseType = null
            newSelectedCaseTypeGroup = null
            showCaseTypeModal = false
          "
        >
          <template v-slot:header>
            <h2>{{ $t(`select_${translationPrefix}_type`) }}</h2>
          </template>
          <template v-slot:body>
            <div class="new-catalog-page__case-types-list">
              <div
                v-for="caseTypeGroup in availableCaseTypeGroupsBySelectedDevice"
                :key="caseTypeGroup.name"
                :class="[
                  'new-catalog-page__case-types-item',
                  {
                    'new-catalog-page__case-types-item--active': newSelectedCaseTypeGroup
                      ? newSelectedCaseTypeGroup.name === caseTypeGroup.name
                      : selectedCaseTypeGroup &&
                        selectedCaseTypeGroup.name === caseTypeGroup.name,
                  },
                ]"
                @click="selectCaseTypeGroup(caseTypeGroup)"
              >
                <img :src="caseTypeGroup.image_url" :alt="caseTypeGroup.name" />
                <div class="new-catalog-page__case-types-item-body">
                  <h4>{{ caseTypeGroup.name }}</h4>
                  <p>{{ caseTypeGroup.description }}</p>
                  <div
                    v-if="caseTypeGroupPrice(caseTypeGroup.case_device_id)"
                    class="new-catalog-page__case-types-item-price"
                  >
                    {{ caseTypeGroupPrice(caseTypeGroup.case_device_id) }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                filteredCaseTypesBySelectedCaseTypeGroup.length > 1 &&
                (newSelectedCaseTypeGroup || selectedCaseTypeGroup)
              "
              class="new-catalog-page__case-types-childs"
            >
              <h4>
                COR ({{
                  newSelectedCaseType
                    ? newSelectedCaseType.name
                    : selectedCaseType && selectedCaseType.name
                }}):
              </h4>
              <div
                v-for="caseType in filteredCaseTypesBySelectedCaseTypeGroup"
                :key="caseType.name"
                :class="[
                  'new-catalog-page__case-types-child',
                  {
                    'new-catalog-page__case-types-child--active': newSelectedCaseType
                      ? newSelectedCaseType.name === caseType.name
                      : selectedCaseType &&
                        selectedCaseType.name === caseType.name,
                  },
                ]"
                @click="newSelectedCaseType = caseType"
              >
                <img :src="caseType.case_image" :alt="caseType.name" />
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <div class="new-catalog-page__case-types-footer">
              <button
                class="new-catalog-page__case-types-select"
                @click="selectCaseType"
              >
                SELECIONAR
              </button>
            </div>
          </template>
        </middle-modal>
      </transition>

      <div class="new-catalog-page__filters">
        <button
          v-if="useCaseScope"
          :class="[
            'new-catalog-page__filters-filter',
            {
              'new-catalog-page__filters-filter--active': !!selectedDevice,
            },
          ]"
          @click="showDeviceModal = true"
        >
          <span v-if="!selectedDevice">{{
            $t(`${translationPrefix}_model`)
          }}</span>
          <span v-else>{{ selectedDevice.name }}</span>
          <icon-arrow-down class="new-catalog-page__filters-filter-icon-down" />
        </button>

        <button
          v-if="useCaseScope"
          :class="[
            'new-catalog-page__filters-filter',
            {
              'new-catalog-page__filters-filter--case-type-active': !!selectedCaseType,
              'new-catalog-page__filters-filter--active': !!selectedCaseType,
            },
          ]"
          @click="openCaseTypeModal"
        >
          <img
            v-if="selectedCaseType"
            :src="selectedCaseType.case_image"
            alt="Case Type Image"
          />
          <span v-if="!selectedCaseType">{{
            $t(`${translationPrefix}_type`)
          }}</span>
          <span v-else>{{ selectedCaseType.name }}</span>
          <icon-arrow-down class="new-catalog-page__filters-filter-icon-down" />
        </button>

        <button
          v-if="enable360PrintFilter"
          v-click-outside="
            () =>
              showPrintTypeModal ? (showPrintTypeModal = false) : () => ({})
          "
          :class="[
            'new-catalog-page__filters-filter',
            {
              'new-catalog-page__filters-filter--active': !!selectedPrintType,
            },
          ]"
          @click="showPrintTypeModal = true"
        >
          <span v-if="!selectedPrintType">{{ $t('print_type') }}</span>
          <span v-else>{{ selectedPrintType.name }}</span>
          <icon-arrow-down class="new-catalog-page__filters-filter-icon-sort" />
          <transition name="fade">
            <div
              v-if="showPrintTypeModal"
              class="new-catalog-page__filters-sort-modal"
            >
              <h4>{{ $t('print_type') }}</h4>
              <div
                v-for="sort in sortByPrints"
                :key="sort.code"
                :class="[
                  'new-catalog-page__filters-sort-modal-item',
                  {
                    'new-catalog-page__filters-sort-modal-item--active':
                      selectedPrintType && selectedPrintType.code === sort.code,
                  },
                ]"
                @click="selectPrint(sort)"
              >
                {{ sort.name }}
              </div>
            </div>
          </transition>
        </button>
        <button
          v-if="isPowerBankPage || (materialsFilter && materialsFilter.length)"
          v-click-outside="
            () =>
              showMaterialFilter ? (showMaterialFilter = false) : () => ({})
          "
          :class="[
            'new-catalog-page__filters-filter',
            {
              'new-catalog-page__filters-filter--active': !!selectedPrintType,
            },
          ]"
          @click="showMaterialFilter = true"
        >
          <span v-if="!selectedMaterialFilter">Cor do Material</span>
          <span v-else>{{ selectedMaterialFilter.name }}</span>
          <icon-arrow-down class="new-catalog-page__filters-filter-icon-sort" />
          <transition name="fade">
            <div
              v-if="showMaterialFilter"
              class="new-catalog-page__filters-sort-modal"
            >
              <h4>Cor do Material</h4>
              <div
                v-for="material in sortByMaterialsFilter"
                :key="material.code"
                :class="[
                  'new-catalog-page__filters-sort-modal-item',
                  {
                    'new-catalog-page__filters-sort-modal-item--active':
                      selectedMaterialFilter &&
                      selectedMaterialFilter.code === material.code,
                  },
                ]"
                @click="selectMaterialFilter(material)"
              >
                {{ material.name }}
              </div>
            </div>
          </transition>
        </button>

        <button
          v-click-outside="
            () => (showSortModal ? (showSortModal = false) : () => ({}))
          "
          :class="[
            'new-catalog-page__filters-filter',
            {
              'new-catalog-page__filters-filter--active': !!selectedSort,
            },
          ]"
          @click="showSortModal = true"
        >
          <span v-if="!selectedSort">Ordenar Por</span>
          <span v-else>{{ selectedSort.name }}</span>
          <icon-up-down class="new-catalog-page__filters-filter-icon-sort" />
          <transition name="fade">
            <div
              v-if="showSortModal"
              class="new-catalog-page__filters-sort-modal"
            >
              <h4>Ordenar Por:</h4>
              <div
                v-for="sort in sortByValues"
                :key="sort.code"
                :class="[
                  'new-catalog-page__filters-sort-modal-item',
                  {
                    'new-catalog-page__filters-sort-modal-item--active':
                      selectedSort && selectedSort.code === sort.code,
                  },
                ]"
                @click="selectSort(sort)"
              >
                {{ sort.name }}
              </div>
            </div>
          </transition>
        </button>
      </div>
      <div class="new-catalog-page__section-title">
        <h4>
          Coleções disponíveis
          <span
            v-if="
              lastSelectedCategory ||
              (selectedCategory && selectedCategory.children.length)
            "
            >em
            <b>
              {{
                selectedCategory.children.length
                  ? selectedCategory.name
                  : lastSelectedCategory && lastSelectedCategory.name
              }}
            </b></span
          >
        </h4>
      </div>
      <div class="new-catalog-page__categories-container">
        <div
          :class="[
            'new-catalog-page__categories',
            {
              'new-catalog-page__categories--mobile': $device.isMobile,
            },
          ]"
        >
          <button
            v-for="category in categoriesToShow"
            :key="category.id"
            :class="[
              'new-catalog-page__categories-item',
              {
                'new-catalog-page__categories-item--active':
                  selectedCategory && selectedCategory.id === category.id,
              },
            ]"
            @click="selectCategory(category)"
          >
            {{ category.name }}
          </button>
        </div>
      </div>
      <div class="new-catalog-categories__nav">
        <button
          :class="[
            'new-catalog-categories__nav-item',
            'new-catalog-categories__nav-item--left',
            {
              'new-catalog-categories__nav-item--hide':
                !translateX || translateX === 0 || $device.isMobile,
            },
          ]"
          @click="navGallery(1)"
        >
          <ArrowLeft class="new-catalog-categories__arrow" />
        </button>
        <button
          :class="[
            'new-catalog-categories__nav-item',
            'new-catalog-categories__nav-item--right',
            {
              'new-catalog-categories__nav-item--hide': $device.isMobile,
            },
          ]"
          @click="navGallery(-1)"
        >
          <ArrowRight class="new-catalog-categories__arrow" />
        </button>
      </div>
      <div v-if="tags.length" class="new-catalog-page__section-title">
        <h4>Filtros selecionados</h4>
      </div>
      <div v-if="tags.length" class="new-catalog-page__tags">
        <button
          v-for="tag in tags"
          :key="tag.name"
          :class="['new-catalog-page__tags-item']"
        >
          {{ tag.name }}
          <span
            class="new-catalog-page__tags-item-remove"
            @click="removeTag(tag)"
          >
            <cross-icon />
          </span>
        </button>
      </div>
    </section>
    <transition name="fade">
      <div
        v-if="showScrollPageToTop && !showDeviceModal"
        class="new-catalog-page__to-top"
        @click="gotoTop"
      >
        <small>Página: {{ page }}/{{ pageQuantity }}</small>
        <button>
          <icon-arrow-up />
        </button>
      </div>
    </transition>
    <div class="section new-catalog-page__products">
      <p>
        {{ totalProducts }} produtos encontrados
        <transition name="fade">
          <span v-if="loadingProducts"><three-dots-loading /></span>
        </transition>
      </p>
      <products-grid
        v-if="products.length"
        :case-device="caseDeviceSlug"
        :case-device-id="caseDeviceId"
        :products="products"
        :send-gtm-data="true"
        :max-grid-columns="4"
        :allow-all-colors="allowAllColors"
      />

      <transition name="fade">
        <span v-if="loadingProducts" class="products-loading">
          <logo-loading />
        </span>
      </transition>
    </div>
  </main>
</template>

<script>
/* eslint-disable camelcase */

import LogoLoading from '../loadings/logo/LogoLoading.vue'
import IconArrowDown from '~/components/icons/toolbar/ArrowDown.vue'
import IconUpDown from '~/components/icons/toolbar/ArrowUpDown'
import ProductsGrid from '~/components/ProductsGrid.vue'
import CrossIcon from '~/components/icons/cross/Cross.vue'
import ThreeDotsLoading from '~/components/loadings/three-dots/ThreeDotsLoading.vue'
import IconArrowUp from '~/components/icons/toolbar/ArrowUp.vue'
import ArrowLeft from '~/components/icons/arrow-left/ArrowLeft'
import ArrowRight from '~/components/icons/arrow-right/ArrowRight'
import SeeMore from '~/components/catalog/SeeMore.vue'

import scrollingNavigator from '~/mixins/scrolling-navigator'
import toQueryString from '~/utils/toQueryString.js'
import clickOutside from '~/mixins/click-outside'
import priceable from '~/mixins/priceable'

const CatalogModal = () =>
  import(
    /* webpackChunkName: "catalog-modal"  */ '~/components/new-catalog/CatalogModal.vue'
  )
const MiddleModal = () =>
  import(
    /* webpackChunkName: "middle-modal"  */ '~/components/middle-modal/MiddleModal.vue'
  )

const PRODUCT_ID = 5507
const VARIANT_ID = 5243

const PRODUCT_ID_GARRAFA = 32801
const VARIANT_ID_GARRAFA = 35084

const PRODUCT_ID_GARRAFA_URBAN = 39288
const VARIANT_ID_GARRAFA_URBAN = 41571

const PRODUCT_ID_CARREGADOR = 33867
const VARIANT_ID_CARREGADOR = 36150

export default {
  name: 'NewCatalogPage',
  components: {
    CatalogModal,
    MiddleModal,
    LogoLoading,
    IconArrowDown,
    IconUpDown,
    ProductsGrid,
    CrossIcon,
    ThreeDotsLoading,
    IconArrowUp,
    ArrowLeft,
    ArrowRight,
    SeeMore,
  },
  mixins: [clickOutside, scrollingNavigator, priceable],
  props: {
    productBaseId: {
      type: Number,
      default: null,
    },
    variantBaseId: {
      type: Number,
      default: null,
    },
    materialsFilter: {
      type: Array,
      default: null,
    },
    useCaseScope: {
      type: Boolean,
      default: false,
    },
    useKitTaxons: {
      type: Boolean,
      default: false,
    },
    enable360PrintFilter: {
      type: Boolean,
      default: false,
    },
    isPowerBankPage: {
      type: Boolean,
      default: false,
    },
    productFilterTaxonId: {
      type: Number,
      default: null,
    },
    isBottlePage: {
      type: Boolean,
      default: null,
    },
    isUrbanBottlePage: {
      type: Boolean,
      default: null,
    },
    translationPrefix: {
      type: String,
      default: 'case',
    },
    mainDescription: {
      type: String,
      default: null,
    },
    taxon: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    productFilterSlug: {
      type: String,
      default: null,
    },
    allowAllColors: {
      type: Boolean,
      default: false,
    },
  },
  async fetch() {
    await this.fetchPageConfigs()
    await this.fetchDevicesAndCaseTypes()
    await this.fetchCategories()
    await this.setInitialFiltersFromQuery()
    await this.fetchProducts()
  },
  data() {
    return {
      brands: {},
      stamp360: false,
      selectedPrintType: null,
      showPrintTypeModal: false,
      showScrollPageToTop: false,
      loadingProducts: true,
      showDeviceModal: false,
      showCaseTypeModal: false,
      showSortModal: false,
      selectedDevice: null,
      selectedCaseType: null,
      showMaterialFilter: false,
      selectedMaterialFilter: null,
      selectedSort: {
        name: 'Favoritos',
        code: 'favorites',
      },
      autoOpenCaseTypeModal: false,
      newSelectedCaseType: null,
      caseTypeGroups: [],
      perPage: 21,
      page: 1,
      products: [],
      totalProducts: 0,
      caseDeviceId: null,
      skipQueryUrlHistory: true,
      selectedCaseTypeGroup: null,
      newSelectedCaseTypeGroup: null,
      availableCategories: [],
      selectedCategory: null,
      selectedCategories: [],
      categoriesToShow: [],
      pageDescription: null,
      pageTitle: null,
      meta: {},
      productFilter: null,
      bannerUrl: null,
      caseDeviceSlug: null,
      defaultCaseDeviceSlug: null,
      caseTypeGroupsPrices: [],
      currentPermalink: null,
    }
  },
  computed: {
    productId() {
      if (this.productBaseId) return this.productBaseId

      const path = this.$route.path
      if (path.match('urban')) {
        return PRODUCT_ID_GARRAFA_URBAN
      }
      if (path.match('garrafa')) {
        return PRODUCT_ID_GARRAFA
      }
      if (path.match('carregadores')) {
        return PRODUCT_ID_CARREGADOR
      }
      return PRODUCT_ID
    },
    variantId() {
      if (this.variantBaseId) return this.variantBaseId

      const path = this.$route.path
      if (path.match('urban')) {
        return VARIANT_ID_GARRAFA_URBAN
      }
      if (path.match('garrafa')) {
        return VARIANT_ID_GARRAFA
      }
      if (path.match('carregadores')) {
        return VARIANT_ID_CARREGADOR
      }
      return VARIANT_ID
    },
    listClassName() {
      return 'new-catalog-page__categories'
    },
    selectedDeviceCode() {
      return this.selectedDevice?.code
    },
    filteredCaseTypesBySelectedCaseTypeGroup() {
      let caseTypes = (this.newSelectedCaseTypeGroup
        ? this.newSelectedCaseTypeGroup
        : this.selectedCaseTypeGroup
      ).case_types

      caseTypes = caseTypes.filter((ct) =>
        ct.devices.map((dv) => dv.device_id).includes(this.selectedDevice?.id)
      )

      return caseTypes
    },
    pageQuantity() {
      return Math.ceil(this.totalProducts / this.perPage)
    },
    lastSelectedCategory() {
      return this.selectedCategories[this.selectedCategories.length - 2]
    },
    tags() {
      return [
        {
          id: 0,
          name: this.selectedDevice?.name,
          kind: 'device',
        },
        {
          id: 1,
          name: this.selectedCaseType?.name,
          kind: 'caseType',
        },
        {
          id: 2,
          name: this.selectedSort?.name,
          kind: 'sort',
        },
        ...this.selectedCategories.map((cat, idx) => ({
          id: idx,
          name: cat.name,
          kind: 'category',
        })),
      ].filter((tag) => !!tag.name)
    },
    sortByValues() {
      return [
        {
          name: 'Favoritos',
          code: 'favorites',
        },
        {
          name: 'Lançamentos',
          code: 'newest',
        },
        {
          name: 'Mais relevantes',
          code: 'relevance',
        },
        {
          name: 'Nome',
          code: 'name',
        },
      ]
    },
    sortByPrints() {
      return [
        {
          name: this.$t('print_type_360'),
          code: '1',
        },
        {
          name: this.$t('print_type_common'),
          code: null,
        },
      ]
    },

    sortByMaterialsFilter() {
      return this.materialsFilter?.length
        ? this.materialsFilter.map((mf) => ({
            name: mf.name,
            code: mf.material,
          }))
        : [
            {
              name: 'Material Preto',
              code: '10000mahpreto-suporteparacelular',
            },
            {
              name: 'Material Branco',
              code: '10000mahbranco-suporteparacelular',
            },
          ]
    },

    availableCaseTypeGroupsBySelectedDevice() {
      let caseTypeGroups = this.caseTypeGroups.filter((group) => {
        return group.case_types.find((type) => {
          return type.devices.find(
            (d) => d.device_id === this.selectedDevice?.id
          )
        })
      })

      caseTypeGroups = caseTypeGroups.map((group) => {
        // eslint-disable-next-line camelcase
        group.case_device_id = this.getOptionDevice(group)?.case_device_id
        return group
      })

      caseTypeGroups = caseTypeGroups
        .map((caseTypeGroup) => {
          return {
            ...caseTypeGroup,
            case_types: caseTypeGroup.case_types.filter((caseType) => {
              const inStock = caseType.devices.find(
                (dev) => dev.device_id === this.selectedDevice?.id
              )?.in_stock

              return inStock
            }),
          }
        })
        .filter((caseTypeGroup) => caseTypeGroup.case_types.length > 0)

      this.updateCaseTypeGroupsPrices(caseTypeGroups)

      return caseTypeGroups
    },
    queryUrl() {
      const obj = {
        ...this.$route.query,
        case_device:
          this.selectedDeviceCode &&
          this.caseDeviceSlug?.match(this.selectedDeviceCode)
            ? this.caseDeviceSlug
            : null,
        product_filter: this.productFilter,
        page: this.page || 1,
        sorting: this.selectedSort?.code,
        taxon: this.currentPermalink,
        new: '1',
      }

      if (this.selectedPrintType?.code) {
        obj.stamp_360 = this.selectedPrintType?.code
      }

      if (this.selectedMaterialFilter?.code) {
        obj.material_slug = this.selectedMaterialFilter.code
      }

      for (const o in obj) {
        if (!obj[o]) {
          delete obj[o]
        }
      }
      return toQueryString(obj)
    },
  },
  watch: {
    queryUrl(queryUrl, oldQueryUrl) {
      let appendProduct = false
      const regex = /(?:[?&]page=)(\d+)/
      const oldPage = oldQueryUrl.match(regex)
      const currentPage = queryUrl.match(regex)

      if (oldPage && currentPage && oldPage[1] !== currentPage[1]) {
        appendProduct = true
      }
      if (!this.skipQueryUrlHistory) {
        window.history.pushState({}, '', `${this.$route.path}/?${queryUrl}`)
        this.fetchProducts(appendProduct)
      }
    },
    selectedCategories(categories) {
      this.selectedCategory = categories.length
        ? categories[categories.length - 1]
        : null
    },
    selectedCategory(category) {
      if (category) {
        this.currentPermalink = category.permalink
        if (category.children.length) {
          this.categoriesToShow = category.children
          this.$el.querySelector('.new-catalog-page__categories').scrollLeft = 0
          this.updateSelectorScroll(0)
        }
      } else {
        this.currentPermalink = null
        this.categoriesToShow = this.availableCategories
      }
    },
    selectedDevice(device) {
      if (!device) {
        this.caseDeviceSlug = this.defaultCaseDeviceSlug
        this.selectedCaseType = null
      } else if (this.selectedCaseType?.code) {
        this.caseDeviceSlug = `${this.selectedCaseType?.code}-${device.code}`
      }
    },
    selectedCaseType(caseType) {
      if (caseType?.code) {
        this.caseDeviceSlug = `${caseType.code}-${this.selectedDevice.code}`
      } else if (
        this.selectedDevice &&
        this.caseDeviceSlug !== this.defaultCaseDeviceSlug
      ) {
        const caseTypeGroup = this.availableCaseTypeGroupsBySelectedDevice[0]
        this.selectCaseTypeGroup(caseTypeGroup)
        this.selectCaseType()
      } else {
        this.caseDeviceSlug = this.defaultCaseDeviceSlug
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.startAutoSelectCategoriesFromQuery()
      this.startInfiniteScroll()
    })
  },
  methods: {
    caseTypeGroupPrice(caseDeviceId) {
      if (!caseDeviceId || !this.caseTypeGroupsPrices.length) return null
      const price = this.caseTypeGroupsPrices.find(
        (price) => price.case_device_id === caseDeviceId
      )
      return price?.price ? this.formatPrice(price.price) : null
    },
    async updateCaseTypeGroupsPrices(caseTypeGroups) {
      const caseDeviceIds = caseTypeGroups.map((co) => co.case_device_id)

      try {
        this.caseTypeGroupsPrices = await this.$axios.$get('/products/price', {
          params: {
            ...this.$store.getters.storeInfo,
            case_device_ids: caseDeviceIds.join(','),
            product_id: this.productId,
            variant_id: this.variantId,
          },
        })
      } catch (err) {
        this.caseTypeGroupsPrices = []
      }
    },
    getOptionDevice(option) {
      return (
        option.case_types.find(
          (ct) => ct.id === Number(option.case_type_default_id)
        ) || option.case_types[0]
      ).devices.find((dv) => dv.device_id === this.selectedDevice?.id)
    },
    async fetchPageConfigs() {
      const deviceSlug =
        this.$route.params.slug ||
        this.$route.query.device_slug ||
        this.$route?.params?.pathMatch?.split('/')?.[1] ||
        this.$route?.params?.pathMatch?.split('/')?.[0]

      const result = await this.$apiv2.$get(`/case_devices/${deviceSlug}`, {
        params: {
          ...this.$store.getters.storeInfo,
          product_filter_slug: this.productFilterSlug,
        },
      })

      this.bannerUrl = result.banner_url
      this.productFilter = result.product_filter?.slug || 'capinha-para-celular'
      this.pageTitle = result.device_name || result.meta.meta_title
      this.pageDescription =
        this.mainDescription ||
        (deviceSlug
          ? result.device_description || result.meta.meta_description
          : null)
      this.meta = result.meta
      this.caseDeviceSlug = result.case_device
    },
    gotoTop() {
      window.scrollTo({
        top:
          (document.querySelector('.new-catalog-page__filters')?.offsetTop ||
            0) -
          ((this.$device.isMobile ? 55 : 0) + 30),
        behavior: 'smooth',
      })
    },
    startAutoSelectCategoriesFromQuery() {
      const cat =
        this.selectedCategories.length > 0
          ? this.selectedCategories[this.selectedCategories.length - 1]
          : null

      if (cat && cat.children && cat.children.length) {
        return
      }

      const selectedCategoryLeft = this.$el.querySelector(
        '.new-catalog-page__categories-item--active'
      )?.offsetLeft

      if (this.$device.isDesktop) {
        let clientWidth = document.querySelector(
          '.new-catalog-page__categories'
        )?.clientWidth

        if (clientWidth > 1170) clientWidth = 1170

        const generateTranslateXToActiveCategoryWithoutPassTheScrollLimit = (
          left
        ) => {
          const translateX = left - clientWidth / 2
          const maxTranslateX =
            this.$el.querySelector('.new-catalog-page__categories')
              .scrollWidth - clientWidth
          if (translateX > maxTranslateX) {
            return maxTranslateX
          }
          return translateX
        }

        this.updateSelectorScroll(
          -generateTranslateXToActiveCategoryWithoutPassTheScrollLimit(
            selectedCategoryLeft - (window.innerWidth > 1000 ? 200 : 0)
          )
        )
      } else {
        this.$el.querySelector('.new-catalog-page__categories').scrollLeft =
          selectedCategoryLeft - 20
      }
    },
    startInfiniteScroll() {
      const infitineScrollProductsPagination = () => {
        const productsContainer = document.querySelector(
          '.new-catalog-page__products'
        )
        if (productsContainer) {
          const rect = productsContainer?.getBoundingClientRect()
          const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop
          const bottom = rect.bottom + scrollTop
          if (
            !this.loadingProducts &&
            window.innerHeight + window.scrollY >= bottom &&
            this.pageQuantity > this.page
          ) {
            this.page++
          }

          const offsetTop = document.querySelector(
            '.new-catalog-page__products'
          ).offsetTop

          if (window.scrollY > offsetTop) {
            this.showScrollPageToTop = true
          } else {
            this.showScrollPageToTop = false
          }
        }
      }
      window.addEventListener('scroll', infitineScrollProductsPagination)
    },
    removeTag(tag) {
      if (tag.kind === 'category') {
        this.selectedCategories = this.selectedCategories.filter(
          (cat) => cat.name !== tag.name
        )
      } else {
        if (tag.kind === 'device') this.selectedDevice = null
        if (tag.kind === 'caseType') this.selectedCaseType = null
        if (tag.kind === 'sort') this.selectedSort = null
      }
    },
    selectCategory(category) {
      this.page = 1
      if (this.selectedCategory?.id !== category.id) {
        if (!this.selectedCategory?.children?.length) {
          this.selectedCategories.pop()
        }
        this.selectedCategories.push(category)
      }
    },
    async fetchCategories() {
      const params = {
        ...this.$store.getters.storeInfo,
        filter_taxon_id: this.productFilterTaxonId,
      }
      if (this.useKitTaxons) params.filter_for_kits = true

      this.availableCategories = await Promise.all([
        ...(
          await this.$axios.$get(`/taxons/children/colecoes`, {
            params,
          })
        ).children,
        ...(
          await this.$axios.$get(`/taxons/children/themes`, {
            params,
          })
        ).children,
      ])
      const storeCode = this.$store.state.storeCode

      const sortTaxonsBySales = (categories) => {
        categories.forEach((cat) => {
          if (!cat[`${storeCode}_sales_last_14_days`])
            cat[`${storeCode}_sales_last_14_days`] = 0

          if (cat.children) {
            sortTaxonsBySales(cat.children)
          }
        })
        categories.sort((a, b) => {
          if (
            a[`${storeCode}_sales_last_14_days`] >
            b[`${storeCode}_sales_last_14_days`]
          ) {
            return -1
          }
          if (
            a[`${storeCode}_sales_last_14_days`] <
            b[`${storeCode}_sales_last_14_days`]
          ) {
            return 1
          }
          return 0
        })
      }

      sortTaxonsBySales(this.availableCategories)

      this.availableCategories = this.availableCategories.filter(
        (cat) => !!cat.name
      )
      this.categoriesToShow = this.availableCategories
    },
    selectSort(sort) {
      this.selectedSort = sort
      this.showSortModal = false
    },
    selectPrint(sort) {
      this.selectedPrintType = sort
      this.showPrintTypeModal = false
    },
    selectMaterialFilter(materialCode) {
      this.selectedMaterialFilter = materialCode
      this.showMaterialFilter = false
    },
    caseTypeIsSelected(caseTypeGroup) {
      const caseTypes = caseTypeGroup.case_types
      const isSelected = !!caseTypes.find(
        (type) => type.id === this.selectedCaseType?.id
      )
      return isSelected
    },
    async setInitialFiltersFromQuery() {
      this.$route.query.taxon =
        this.$route.query?.taxon || this.taxon?.permalink
      const query = this.$route.query

      if (query.taxon) {
        this.currentPermalink = query.taxon

        const findTaxonInTaxonChildrenAndSaveTheTree = async (taxon) => {
          const taxonChildren = taxon.children
          const foundTaxon = taxonChildren.find(
            (child) => child.permalink === query.taxon
          )
          if (foundTaxon) {
            this.selectCategory(taxon)
            await new Promise((resolve, reject) =>
              setTimeout(() => {
                this.selectCategory(foundTaxon)
                resolve(true)
              }, 100)
            )
          } else {
            await Promise.all(
              taxonChildren.map(async (child) => {
                await findTaxonInTaxonChildrenAndSaveTheTree(child)
              })
            )
          }
        }

        await findTaxonInTaxonChildrenAndSaveTheTree({
          children: this.availableCategories,
        })
      }

      if (query.sorting) {
        this.selectedSort = this.sortByValues.find(
          (sort) => sort.code === query.sorting
        ) || { code: 'favorites', name: 'favorites' }
      }

      if (query.stamp_360) {
        this.selectedPrintType = this.sortByPrints.find(
          (sort) => sort.code === query.stamp_360
        )
      }

      if (query.material_slug) {
        this.selectedMaterialFilter = this.sortByMaterialsFilter.find(
          (sort) => sort.code === query.material_slug
        )
      }

      const caseDeviceSlug = query.case_device || this.caseDeviceSlug
      this.defaultCaseDeviceSlug = this.caseDeviceSlug

      if (query.case_device || (caseDeviceSlug && this.$route.params.slug)) {
        const [caseTypeCode, deviceCode] = caseDeviceSlug.split('-')
        let caseType = this.caseTypeGroups
          .map((group) => group.case_types)
          .flat()
          .find((type) => type.code === caseTypeCode)

        if (!caseType) {
          caseType = this.caseTypeGroups
            .map((group) => group.case_types)
            .flat()[0]
        }

        const devicesFromBrands = Object.values(this.brands)
          .map((device) => device)
          .flat()

        const findDeviceFromBrands = (brands) => {
          return devicesFromBrands.find((device) => device.code === deviceCode)
        }

        const device = findDeviceFromBrands(this.brands)

        this.selectedDevice = device
        this.selectedCaseType = caseType
        this.selectedCaseTypeGroup = this.caseTypeGroups.find((group) =>
          group.case_types.find((type) => type.id === caseType.id)
        )
      } else {
        const pathSlug = this.$route.params.slug

        if (pathSlug) {
          const selectDeviceBySlug = (slug) => {
            const devicesFromBrands = Object.values(this.brands).flat()

            const findDeviceFromBrands = (brands) => {
              return devicesFromBrands.find((device) => device.slug === slug)
            }

            const device = findDeviceFromBrands(this.brands)

            if (device) {
              this.selectedDevice = device
              this.selectCaseTypeByDevice(device)
            }
          }

          selectDeviceBySlug(pathSlug)
        }
      }
    },
    selectCaseTypeGroup(caseTypeGroup) {
      this.newSelectedCaseTypeGroup = caseTypeGroup
      const defaultCaseTypeId = Number(caseTypeGroup.case_type_default_id || 0)
      this.newSelectedCaseType = defaultCaseTypeId
        ? caseTypeGroup.case_types.find((ct) => ct.id === defaultCaseTypeId)
        : caseTypeGroup.case_types[0]
    },
    selectCaseType() {
      this.showCaseTypeModal = false
      if (this.newSelectedCaseType) {
        this.selectedCaseType = this.newSelectedCaseType
        this.selectedCaseTypeGroup = this.newSelectedCaseTypeGroup
      }
      this.newSelectedCaseType = null
      this.newSelectedCaseTypeGroup = null
    },
    openCaseTypeModal() {
      if (!this.selectedDevice) {
        this.showDeviceModal = true
        this.autoOpenCaseTypeModal = true
        return
      }
      this.showCaseTypeModal = true
    },
    selectDevice(device) {
      this.showDeviceModal = false
      this.selectedDevice = device
      this.selectCaseTypeByDevice(device)
      if (this.autoOpenCaseTypeModal) {
        this.autoOpenCaseTypeModal = false
        setTimeout(() => {
          this.showCaseTypeModal = true
        }, 300)
      }
    },
    selectCaseTypeByDevice(device) {
      const ctName = 'Slim Air' // se o preco n interessar mantem so Slim
      const restTypes = this.caseTypeGroups.filter(
        (op) => !op.name?.match(ctName)
      )
      const mainTypes = this.caseTypeGroups.filter((op) =>
        op.name?.match(ctName)
      )

      const caseTypeGroups = [...mainTypes, ...restTypes]

      const caseTypeGroup =
        caseTypeGroups.find((group) => {
          return group.case_types.find((type) => {
            return (
              type.devices.find((d) => d.device_id === device.id) &&
              type.devices.find((dev) => dev.device_id === device.id)?.in_stock
            )
          })
        }) || caseTypeGroups[0]
      const defaultCaseTypeId = caseTypeGroup.case_type_default_id
      this.selectedCaseType = defaultCaseTypeId
        ? caseTypeGroup.case_types.find(
            (ct) => Number(ct.id) === Number(defaultCaseTypeId)
          )
        : caseTypeGroup.case_types[0]

      this.selectedCaseTypeGroup = caseTypeGroup
    },
    async fetchProducts(appendProduct = false) {
      this.loadingProducts = true
      this.skipQueryUrlHistory = false

      const response = await this.$axios.$get(
        `/products/taxons/?${this.queryUrl}&${toQueryString(
          this.$store.getters.storeInfo
        )}`
      )

      const {
        page,
        per_page: perPage,
        total_count: totalProducts,
        case_device_id: caseDeviceId,
      } = response

      const { products } = response

      if (appendProduct) {
        this.products = [...this.products, ...products]
      } else {
        this.products = products
      }

      this.page = page
      this.perPage = perPage
      this.totalProducts = totalProducts
      if (this.selectedDeviceCode) this.caseDeviceId = caseDeviceId
      this.loadingProducts = false
    },
    async fetchDevicesAndCaseTypes() {
      const {
        devices,
        case_type_groups: caseTypeGroups,
      } = await this.$axios.$get('/products/case_types', {
        params: {
          jd: 42,
          product_id: this.productId,
          ...this.$store.getters.storeInfo,
        },
      })

      const brands = {}

      for (const device of devices) {
        const brand = device.brand
        if (!brands[brand]) {
          brands[brand] = []
        }
        brands[brand].push(device)
      }
      // sort brand devices by position
      for (const brand in brands) {
        brands[brand].sort((a, b) => a.position - b.position)
      }

      for (const brand in brands) {
        brands[brand] = brands[brand].filter((device) => {
          return caseTypeGroups.filter(
            (ctg) =>
              ctg.case_types.filter((ct) => {
                return ct.devices.find(
                  (dv) =>
                    Number(dv.device_id) === Number(device.id) && dv.in_stock
                )
              }).length
          ).length
        })
      }

      this.brands = brands
      this.caseTypeGroups = caseTypeGroups
    },
  },
}
</script>

<style lang="scss" scoped>
.new-catalog-categories__nav {
  display: flex;
  justify-content: space-between;

  .new-catalog-categories__arrow {
    width: 25px;
    fill: $v4_color_blue;
    transition: all 0.2s ease-in-out;
  }

  .new-catalog-categories__nav-item {
    // position: absolute;
    // top: 50%;
    margin-top: -37px;
    width: 40px;
    height: 40px;
    line-height: 78px;
    z-index: 5;
    text-align: center;
    border-radius: 50%;
    border: 0;
    background-color: rgba(229, 235, 235, 0.5);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &--left {
      margin-left: -15px;
    }

    &--right {
      margin-right: -15px;
    }

    &:focus {
      outline: 0;
    }
    &:hover {
      background-color: rgba(229, 235, 235, 1);
    }

    &--hide {
      opacity: 0;
    }
  }
}
.products-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
}
.new-catalog-page__to-top {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100px;
  z-index: 300;
  max-width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  transition: opacity 0.3s ease-in-out;
  small {
    font-size: 10px;
    color: #999;
    margin-bottom: 5px;
  }

  button {
    background: $v4_color_primary;
    border: none;
    border-radius: 50%;
    outline: none;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }

  svg {
    fill: white;
    width: 20px;
    height: 20px;
    top: 5px;
    left: 0px;
  }
}
.new-catalog-page {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 15px;

  &__filters-filter {
    position: relative;
    min-width: 160px;
  }

  &__filters-sort-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 10px;
    background: #f2f2f6;
    border-radius: 3px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    color: #333;
    z-index: 300;
    transition: color 0.3s ease-in-out;
  }

  &__filters-sort-modal-item:hover {
    color: $v4_color_primary;
  }

  &__filters-sort-modal-item--active {
    color: $v4_color_primary;
  }

  &__case-types {
    font-size: 16px;
  }

  &__case-types-list,
  &__case-types-childs {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 10px;
  }

  &__case-types-childs {
    margin-top: 20px;

    h4 {
      display: block;
      width: 100%;
      margin: 0px;
    }
  }

  &__case-types-item,
  &__case-types-child {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 130px;
    min-height: 190px;
    text-align: center;
    position: relative;
    transition: border-color 0.3s ease-in-out;

    h4 {
      font-size: 12px;
      -webkit-line-clamp: 2; /* number of lines to show */
      margin-top: 115px;
      padding: 0px 2px;
    }

    @media (max-width: 486px) {
      width: 100px;
      min-height: 150px;

      h4 {
        margin-top: 85px;
        font-size: 9px;
      }
    }

    &-price {
      font-size: 12px;
      left: 0;
      bottom: 0;
      position: absolute;
      width: 100%;
      text-align: center;
      font-weight: 600;
      color: white;
      background: #333;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      transition: background-color 0.3s ease-in-out;
    }

    img {
      width: 100% !important;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 5px;
    }

    &:hover {
      border: 1px solid $v4_color_primary;

      .new-catalog-page__case-types-item-price {
        background: $v4_color_primary;
      }
    }

    &--active {
      border: 1px solid $v4_color_primary;

      .new-catalog-page__case-types-item-price {
        background: $v4_color_primary;
      }
    }
  }

  &__case-types-child {
    width: 60px;
    min-height: 60px;
  }

  &__case-types-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  &__case-types-select {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $v4_color_primary;
    border-radius: 3px;
    padding: 10px 20px;
    border: none;
    font-weight: 600;
    color: white;
  }

  &__body {
    margin-bottom: 20px;
  }

  &__products {
    p {
      margin-bottom: 20px;
      font-size: 15px;
      display: flex;
      color: #888;
      justify-content: flex-start;
      align-items: center;
    }

    span {
      margin-left: 20px;
    }
  }

  &__header {
    margin-bottom: 20px;

    h1 {
      font-size: 25px;
      margin-bottom: 15px;
      font-family: 'Lato';
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      column-gap: 10px;

      span {
        color: $v4_color_primary;
      }
    }

    img {
      width: 100%;
      border-radius: 5px;
    }
  }

  &__case-types {
    &-select {
    }
  }

  &__section-title {
    margin-top: 20px;
    margin-bottom: 10px;

    h4 {
      font-weight: 500;
      font-size: 15px;
    }
  }

  &__categories-container {
    overflow: hidden;
    margin-left: -15px;
    padding-left: 15px;
    margin-right: -15px;
    padding-right: 15px;
  }

  &__categories {
    overflow: visible;
    white-space: nowrap;
    transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    margin-left: -15px;
    padding-left: 15px;
    margin-right: -15px;
    padding-right: 15px;

    &--mobile {
      overflow: auto;
    }

    &-item {
      display: inline-block;
      margin-right: 10px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    & {
      -ms-overflow-style: none;
    }

    overflow: -moz-scrollbars-none;
  }

  &__tags {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
  }

  &__tags-item {
    border-radius: 25px;
    padding: 5px 10px;
    background: $v4_color_primary_light;
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;

    &-remove {
      margin-left: 10px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 3px;
      cursor: pointer;
    }

    svg {
      width: 10px;
    }
  }

  &__categories-item {
    white-space: nowrap;
    background: none;
    font-weight: bold;
    border-radius: 3px;
    border: 1px solid $v4_color_primary;
    padding: 5px 10px;
    height: 35px;
    cursor: pointer;
    font-size: 15px;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

    &:hover {
      background: $v4_color_primary_light;
    }

    &--active {
      background: $v4_color_primary_light;

      &:hover {
        background: $v4_color_primary_light;
      }
    }
  }

  &__filters {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    &-filter {
      background: #f2f2f6;
      border-radius: 3px;
      padding: 5px 30px;
      padding-left: 10px;
      font-size: 15px;
      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
      border: none;
      outline: none;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      white-space: normal;

      > span {
        -webkit-line-clamp: 2; /* number of lines to show */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 14px;
      }

      &--case-type-active {
        margin-left: 38px;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;

        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 3px;
          position: absolute;
          left: -38px;
          top: 0px;
          border: 0.5px solid $v4_color_primary;
          border-bottom-left-radius: 3px;
          border-top-left-radius: 3px;
        }
      }

      &--active {
        font-weight: 600;
      }

      &-icon-sort {
        fill: #333;
        position: absolute;
        top: 12px;
        right: 10px;
      }

      &-icon-down {
        fill: #333;
        margin-top: 3px;
        position: absolute;
        top: 10px;
        right: 10px;
      }

      @media (max-width: 520px) {
        min-width: 160px;
        flex: 1;
      }
    }

    &-filter:hover {
      background-color: #d8d8d8;
      cursor: pointer;
    }
  }
}
</style>
